import React from 'react';
import ls from 'local-storage';
import Layout from './layout';
import TopCta from './top-cta';
import CtaButton from './cta-button';
import ProcessStep from './process-step';
import UrlAnimation from './urlAnimation';
import SliderAnimation from './sliderAnimation';
import Contact from './contact';
import HomeCta from './home-cta';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    referralCode: null,
  };

  componentDidMount() {
    const referralCode = this.props.urlQuery.split('r=')[1];
    if (referralCode != undefined) {
      ls.set('referralCode', referralCode);
    }
  }

  render() {
    return (
      <Layout>
        <TopCta>
          <CtaButton
            linkTo="/early-access"
            className="ctaButtonHeader"
            buttonText="Early Access"
          />
        </TopCta>
        <ProcessStep
          stepNumber="1"
          bulletColor="red"
          stepHeader="Start With Your Brand"
          stepSubhead={`What's your brand URL?`}
        >
          <UrlAnimation />
        </ProcessStep>
        <ProcessStep
          stepNumber="2"
          bulletColor="yellow"
          stepHeader="We Build Your Guidelines"
          stepSubhead="Get a cup of coffee. A small one."
        />
        <ProcessStep
          stepNumber="3"
          bulletColor="blue"
          stepHeader="Fine Tuning"
          stepSubhead={`Make any updates and changes. \n You have full control.`}
        >
          <SliderAnimation />
        </ProcessStep>
        <ProcessStep
          stepNumber="4"
          bulletColor="green"
          stepHeader="Publish Your Brand Guidelines"
          stepSubhead="Share your guidelines with customers, partners, the world!"
        />
        <Contact />
        <HomeCta />
      </Layout>
    );
  }
}
export default HomePage;
