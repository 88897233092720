import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Particles from 'react-particles-js';
import ParticleParams from '../particle-params.json';
import ContactText from './contact-text';
import ContactFormContainer from './contact-form-container';
import styles from './contact-block.module.css';
import dots from '../images/dots.svg';

export default class Contact extends React.Component {
  render() {
    return (
      <section className="contact" id="contact">
        <Container className={`flex-container ${styles.contactContainer}`}>
          <Particles
            className={styles.layoutParticles}
            params={ParticleParams}
          />
          <Row className="justify-content-center">
            <Col lg="5" md="10" sm="11" className="contactTextContainer">
              <ContactText />
            </Col>
            <Col lg="4" md="10" sm="11" className="contactFormContainer">
              <ContactFormContainer />
              <img src={dots} alt="" className="formDots" />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
