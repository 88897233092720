import React from 'react';
import CtaButton from './cta-button';
import styles from './home-cta.module.css';
import dotStyles from './home-cta-dot-animations.module.css';
import dots from './images/dots-small.svg';

class HomeCta extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className={styles.ctaFooter}>
        <div className={styles.ctaCircleContainer}>
          <div className={styles.ctaCircleBackground}>&nbsp;</div>
          <div className={styles.ctaCircle}>
            <h1 className={styles.footerCta}>Get Early Access</h1>
            <p className={styles.ctaSubhead}>
              Accelerate your brand's growth. Signup to be among the first.
            </p>
            <CtaButton
              linkTo="/early-access"
              className="ctaButtonFooter"
              buttonText="Early Access"
            />
          </div>
          <img src={dots} className={styles.ctaCircleDots1} />
          <img src={dots} className={styles.ctaCircleDots2} />
          <div className={dotStyles.dotContainer}>
            <div className={dotStyles.dotLightPink} />
            <div className={dotStyles.dotSmallBlue} />
            <div className={dotStyles.dotLightPurple} />
            <div className={dotStyles.dotBrightGreen} />
            <div className={dotStyles.dotMediumBlue} />
            <div className={dotStyles.dotTopYellow} />
            <div className={dotStyles.dotLargePale} />
            <div className={dotStyles.dotRed} />
            <div className={dotStyles.dotBottomYellow} />
          </div>
        </div>
      </section>
    );
  }
}

export default HomeCta;
