import React from 'react';
import EarlyAccessForm from './early-access-form';
import CopyButtonForm from './copy-button-form';
import FormError from '../form-error';

class EarlyAccessFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referralCode: null,
      submitStatus: 'unsubmitted',
    };
    this.setView = this.setView.bind(this);
  }

  setView = (submitStatus, referralCode) => {
    this.setState({
      referralCode,
      submitStatus,
    });
  };

  render() {
    if (this.state.submitStatus === 'submitted') {
      return <CopyButtonForm referralCode={this.state.referralCode} />;
    } else if (this.state.submitStatus === 'unsubmitted') {
      return <EarlyAccessForm submitStatus={this.setView} />;
    } else {
      return (
        <FormError
          text="Something went wrong. We weren't able to sign you up. Please wait a few moments and try again."
          buttonText="Try again"
          containerClassName="earlyAccessErrorContainer"
          submitStatus={this.setView}
        />
      );
    }
  }
}

export default EarlyAccessFormContainer;
