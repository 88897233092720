import React from 'react';
import Particles from 'react-particles-js';
import ParticleParams from './particle-params.json';
import appScreenshot from './images/saas-view-1.svg';
import styles from './top-cta.module.css';

class TopCta extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <header className={styles.ctaHeader}>
        <div className={styles.leftBlock}>
          <div>
            <h1>Automated Brand Guidelines</h1>
            <p className={styles.headerSubhead}>
              Built for your brand, for free
            </p>
            <p className={styles.headerDescription}>
              1Brand automatically builds your brand guidelines for you. Just
              enter your URL, and we take care of the rest.
            </p>
            {this.props.children}
          </div>
        </div>
        <div className={styles.rightBlock}>
          <Particles
            className={styles.layoutParticles}
            params={ParticleParams}
          />
          <img
            src={appScreenshot}
            alt="View of the Logos section of the 1Brand interface"
            className={styles.headerRightAppView}
          />
        </div>
      </header>
    );
  }
}

export default TopCta;
