import React from 'react';
import ContactForm from './contact-form';
import FormError from '../form-error';

class ContactFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitStatus: 'unsubmitted',
    };
    this.setView = this.setView.bind(this);
  }

  setView = submitStatus => {
    this.setState({
      submitStatus,
    });
  };

  render() {
    if (this.state.submitStatus === 'failed') {
      return (
        <FormError
          text="Something went wrong. We weren't able to send your message. Please wait a few moments and try again."
          buttonText="Try again"
          containerClassName="contactErrorContainer"
          submitStatus={this.setView}
        />
      );
    } else {
      return <ContactForm submitStatus={this.setView} />;
    }
  }
}

export default ContactFormContainer;
