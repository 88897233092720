import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styles from './button.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

const CtaButton = props => {
  CtaButton.propTypes = {
    className: PropTypes.string,
    buttonText: PropTypes.string,
    linkTo: PropTypes.string,
  };

  const { className, buttonText, linkTo } = { ...props };
  // Use ClassNames (cx) to join the default .button className with the more specific className for this particular button
  const classNames = cx('button', className);
  return (
    <Link to={linkTo}>
      <button type="button" className={classNames}>
        {buttonText}
      </button>
    </Link>
  );
};

export default CtaButton;
