// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-early-access-js": () => import("/opt/render/project/src/src/pages/early-access.js" /* webpackChunkName: "component---src-pages-early-access-js" */),
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/render/project/src/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/render/project/src/.cache/data.json")

