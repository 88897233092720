import React from 'react';
import PropTypes from 'prop-types';
import Particles from 'react-particles-js';
import ParticleParams from '../particle-params.json';
import BulletPoint from './bullet-point';
import styles from './process-step.module.css';
import illustration01 from '../images/01-illustration.svg';
import illustration02 from '../images/02-illustration.svg';
import illustration03 from '../images/03-illustration.svg';
import illustration04 from '../images/04-illustration.svg';

const ProcessStep = props => {
  ProcessStep.propTypes = {
    stepNumber: PropTypes.string,
    bulletColor: PropTypes.string,
    stepHeader: PropTypes.string,
    stepSubhead: PropTypes.string,
  };
  const { stepNumber, bulletColor, stepHeader, stepSubhead } = {
    ...props,
  };
  // Convert stepNumber string to number. Prevent breaking errors by making sure it's an integer, even if it's the wrong integer
  const stepNumberInt = Math.floor(parseInt(stepNumber, 10));
  // Now
  // put all our illustrations in an array so they can be called by index matching stepNumberInt
  const illustrations = [
    illustration01,
    illustration02,
    illustration03,
    illustration04,
  ];
  const stepImage = illustrations[stepNumberInt - 1];

  return (
    <section className={`styles.step${stepNumberInt}Section`}>
      <div className={styles.leftBlock}>
        <BulletPoint
          stepNumber={stepNumberInt}
          bulletColor={bulletColor}
          stepHeader={stepHeader}
          stepSubhead={stepSubhead}
        />
      </div>
      <div className={styles.rightBlock}>
        <Particles className={styles.layoutParticles} params={ParticleParams} />
        <div className={styles.stepHeroImage}>
          <img
            src={stepImage}
            alt={`Illustration of 1Brand app at step ${stepNumberInt}`}
            className={styles.stepImage}
          />
          {props.children}
        </div>
      </div>
    </section>
  );
};

export default ProcessStep;
