import React from 'react';
import PropTypes from 'prop-types';
import styles from './early-access-form.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

class CopyButtonForm extends React.Component {
  constructor(props) {
    super(props);
    CopyButtonForm.proptypes = {
      referralCode: PropTypes.string.isRequired,
    };
    this.state = { copySuccess: 'Copy' };
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard = event => {
    event.preventDefault();
    this.copyField.select();
    document.execCommand('copy');
    // Puts the focus on the copy button instead of the input
    event.target.focus();
    this.setState({ copySuccess: 'Copied' });
  };

  render() {
    /* If referralUrl has not been copied (based on state), it's a "Copy" button, and it has the normal button background. If it **has** been copied, it's a "Copied" button and has a darker background. */
    const copyButtonClassName =
      this.state.copySuccess === 'Copy'
        ? styles.ctaSuccessCopy
        : styles.ctaSuccessCopied;
    // Use classnames (cx) to join the above className with the universal ".button" className to be passed in all at once to the button.
    const copyButtonClassNames = cx('button', copyButtonClassName);
    return (
      <form className={styles.ctaSuccessForm} action="/" method="post">
        <label htmlFor="ctaReferralUrl" className={styles.ctaSuccessMessage}>
          You're on the list! Share this link to get even earlier access.
        </label>
        <input
          type="text"
          className={styles.referralUrl}
          ref={copyField => (this.copyField = copyField)}
          id="ctaReferralUrl"
          name="ctaReferralUrl"
          // Use the referral code from state, which retrieves its value from our API call, to set the placeholder text that will get copied by the copy button
          value={this.props.referralCode}
          readOnly
        />
        {/* Logical shortcut for only displaying the 
            button if the copy command exists */ document.queryCommandSupported(
          'copy'
        ) && (
          <button
            className={copyButtonClassNames}
            onClick={this.copyToClipboard}
            name="copyButton"
            id="copyButton"
          >
            {this.state.copySuccess}
          </button>
        )}
      </form>
    );
  }
}

export default CopyButtonForm;
