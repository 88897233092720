import React from 'react';
import styles from './contact-block.module.css';

export default class ContactText extends React.Component {
  render() {
    return (
      <div>
        <h1>Get in Touch</h1>
        <p className={styles.contactTextSubhead}>
          We&rsquo;d love to hear from you
        </p>
      </div>
    );
  }
}
