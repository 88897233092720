import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './form-error.module.css';
import buttonStyles from './button.module.css';

class FormError extends React.Component {
  constructor(props) {
    super(props);
    FormError.propTypes = {
      text: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      containerClassName: PropTypes.string.isRequired,
      submitStatus: PropTypes.func.isRequired,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.submitStatus('unsubmitted', null);
  }

  render() {
    let cx = classNames.bind(styles);
    // Use classnames (cx) to join the passed in containerClassName with the universal ".formErrorContainer" className to be passed in all at once to the container div.
    const containerClassName = cx(
      'formErrorContainer',
      this.props.containerClassName
    );
    return (
      <div className={containerClassName}>
        <p className={styles.formErrorText}>{this.props.text}</p>
        <form onSubmit={this.handleSubmit} className="tryAgainForm">
          <button className={buttonStyles.button} type="submit">
            {this.props.buttonText}
          </button>
        </form>
      </div>
    );
  }
}

export default FormError;
