import React from 'react';
import PropTypes from 'prop-types';
import bulletPointStyles from './bullet-point.module.css';

const BulletPoint = props => {
  BulletPoint.propTypes = {
    stepNumber: PropTypes.number,
    bulletColor: PropTypes.string,
    stepHeader: PropTypes.string,
    stepSubhead: PropTypes.string,
  };
  const { stepNumber, bulletColor, stepHeader, stepSubhead } = {
    ...props,
  };
  // Convert our stepNumber to a string and add a leading 0, e.g. "01"
  const number = '0' + parseInt(stepNumber, 10);
  const bulletColorClasses = {
    red: bulletPointStyles.stepBulletRed,
    yellow: bulletPointStyles.stepBulletYellow,
    blue: bulletPointStyles.stepBulletBlue,
    green: bulletPointStyles.stepBulletGreen,
  };
  return (
    <div className={bulletPointStyles.step}>
      <span className={bulletPointStyles.stepNumber}>{number}</span>
      <div className={bulletPointStyles.stepDescription}>
        <span
          className={`${bulletPointStyles.stepBullet} ${
            bulletColorClasses[bulletColor]
          }`}
        />
        <div className={bulletPointStyles.stepText}>
          <p className={bulletPointStyles.stepHeader}>{stepHeader}</p>
          <p className={bulletPointStyles.stepSubhead}>{stepSubhead}</p>
        </div>
      </div>
    </div>
  );
};

export default BulletPoint;
