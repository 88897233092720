import React from 'react';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import styles from './early-access-form.module.css';
import buttonStyles from '../button.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(buttonStyles);

const freeEmailDomains = [
  /* Default domains included */
  'aol.com',
  'att.net',
  'comcast.net',
  'facebook.com',
  'gmail.com',
  'gmx.com',
  'googlemail.com',
  'google.com',
  'hotmail.com',
  'hotmail.co.uk',
  'mac.com',
  'me.com',
  'mail.com',
  'msn.com',
  'live.com',
  'sbcglobal.net',
  'verizon.net',
  'yahoo.com',
  'yahoo.co.uk',

  /* Other global domains */
  'email.com',
  'fastmail.fm',
  'games.com' /* AOL */,
  'gmx.net',
  'hush.com',
  'hushmail.com',
  'icloud.com',
  'iname.com',
  'inbox.com',
  'lavabit.com',
  'love.com' /* AOL */,
  'outlook.com',
  'pobox.com',
  'protonmail.com',
  'rocketmail.com' /* Yahoo */,
  'safe-mail.net',
  'wow.com' /* AOL */,
  'ygm.com' /* AOL */,
  'ymail.com' /* Yahoo */,
  'zoho.com',
  'yandex.com',

  /* United States ISP domains */
  'bellsouth.net',
  'charter.net',
  'cox.net',
  'earthlink.net',
  'juno.com',

  /* British ISP domains */
  'btinternet.com',
  'virginmedia.com',
  'blueyonder.co.uk',
  'freeserve.co.uk',
  'live.co.uk',
  'ntlworld.com',
  'o2.co.uk',
  'orange.net',
  'sky.com',
  'talktalk.co.uk',
  'tiscali.co.uk',
  'virgin.net',
  'wanadoo.co.uk',
  'bt.com',

  /* Domains used in Asia */
  'sina.com',
  'sina.cn',
  'qq.com',
  'naver.com',
  'hanmail.net',
  'daum.net',
  'nate.com',
  'yahoo.co.jp',
  'yahoo.co.kr',
  'yahoo.co.id',
  'yahoo.co.in',
  'yahoo.com.sg',
  'yahoo.com.ph',
  '163.com',
  '126.com',
  'aliyun.com',
  'foxmail.com',

  /* French ISP domains */
  'hotmail.fr',
  'live.fr',
  'laposte.net',
  'yahoo.fr',
  'wanadoo.fr',
  'orange.fr',
  'gmx.fr',
  'sfr.fr',
  'neuf.fr',
  'free.fr',

  /* German ISP domains */
  'gmx.de',
  'hotmail.de',
  'live.de',
  'online.de',
  't-online.de' /* T-Mobile */,
  'web.de',
  'yahoo.de',

  /* Italian ISP domains */
  'libero.it',
  'virgilio.it',
  'hotmail.it',
  'aol.it',
  'tiscali.it',
  'alice.it',
  'live.it',
  'yahoo.it',
  'email.it',
  'tin.it',
  'poste.it',
  'teletu.it',

  /* Russian ISP domains */
  'mail.ru',
  'rambler.ru',
  'yandex.ru',
  'ya.ru',
  'list.ru',

  /* Belgian ISP domains */
  'hotmail.be',
  'live.be',
  'skynet.be',
  'voo.be',
  'tvcablenet.be',
  'telenet.be',

  /* Argentinian ISP domains */
  'hotmail.com.ar',
  'live.com.ar',
  'yahoo.com.ar',
  'fibertel.com.ar',
  'speedy.com.ar',
  'arnet.com.ar',

  /* Domains used in Mexico */
  'yahoo.com.mx',
  'live.com.mx',
  'hotmail.es',
  'hotmail.com.mx',
  'prodigy.net.mx',

  /* Domains used in Brazil */
  'yahoo.com.br',
  'hotmail.com.br',
  'outlook.com.br',
  'uol.com.br',
  'bol.com.br',
  'terra.com.br',
  'ig.com.br',
  'itelefonica.com.br',
  'r7.com',
  'zipmail.com.br',
  'globo.com',
  'globomail.com',
  'oi.com.br',
];

const emailDomainFilter = freeEmailDomains.join('|');

const emailRegexQuery = `^[a-z0-9._%+-]+@(?!${emailDomainFilter})(?:[a-z0-9.-]+\\.[A-Za-z]+)+$`;
const urlRegexQuery =
  '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
const emailRegex = new RegExp(emailRegexQuery, 'i');
const urlRegex = new RegExp(urlRegexQuery, 'i');
const EarlyAccessFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Must be longer than 2 characters')
    .required('Please enter your name'),
  email: Yup.string()
    .matches(emailRegex, 'Please use your company email account')
    .required('Required'),
  url: Yup.string()
    .matches(urlRegex, 'Please enter a valid web address')
    .required('Required'),
  publishes: Yup.string().required('Required'),
});

class EarlyAccessForm extends React.Component {
  constructor(props) {
    super(props);
    EarlyAccessForm.propTypes = {
      submitStatus: PropTypes.func.isRequired,
    };
    this.state = {
      submitted: 'unsubmitted',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    const localReferralCode = ls.get('referralCode');
    const referralCode = localReferralCode === null ? '' : localReferralCode;
    this.setState({ submitted: 'submitting' });
    const airtableFields = { ...formValues, referralCode };
    const apiEndpoint =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3001'
        : 'https://fye036q5t7.execute-api.us-east-2.amazonaws.com/v1/prelaunchEarlyAccessFormProcessor';

    // Submit to AWS API Gateyway for processing by a Lambda function.
    const airtableData = await axios
      .post(apiEndpoint, airtableFields)
      .then(response => {
        const newReferralCode = response.data.newReferralCode;
        this.props.submitStatus(
          'submitted',
          `https://1brand.co/?r=${newReferralCode}`
        );
      })
      .catch(err => {
        if (err.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          console.error(err.response.data);
          console.error(err.response.status);
          console.error(err.response.headers);
        } else if (err.request) {
          // The request was made but no response was received
          console.error(err.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error:', err.message);
        }
        console.error(err.config);
        this.props.submitStatus('failed', null);
      });
  }

  render() {
    /* If form has not been sent (based on state), it's a "Send" button, and it has the normal button background. If form **has** been sent, it's a "Sent" button and has a darker background. */

    const sendButtonText =
      this.state.submitted === 'unsubmitted' ? 'Send' : 'Sending...';
    const sendButtonClassName =
      this.state.submitted === 'unsubmitted'
        ? buttonStyles.earlyAccessSend
        : buttonStyles.earlyAccessSent;
    // Use classnames (cx) to join the above className with the universal ".button" className to be passed in all at once to the button.
    const sendButtonClassNames = cx('button', sendButtonClassName);
    // trailing comma needs to not exist so we will 🔽
    //prettier-ignore
    const initialFormValues = {
      name: '',
      email: '',
      url: '',
      publishes: ''
    };
    return (
      <Formik
        initialValues={initialFormValues}
        validationSchema={EarlyAccessFormSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          actions.resetForm(initialFormValues);
          this.handleSubmit(values);
        }}
        render={({ errors, touched }) => (
          <Form className={styles.ctaForm}>
            <label htmlFor="name">Your Name</label>
            <Field name="name" placeholder="John Doe" type="text" />

            <ErrorMessage name="name" component="div" className="field-error" />

            <label htmlFor="email">Your Business Email</label>
            <Field name="email" placeholder="you@yourbrand.com" type="email" />
            <ErrorMessage
              name="email"
              component="div"
              className="field-error"
            />

            <label htmlFor="url">Your Brand's Website</label>
            <Field name="url" placeholder="https://1brand.co" type="text" />
            <ErrorMessage name="url" component="div" className="field-error" />

            <label htmlFor="publishes">
              Do You Already Publish Your Brand Guidelines?
            </label>
            <Field
              name="publishes"
              id="publishes"
              placeholder="https://1brand.co"
              component="select"
              className={styles.selectPublishes}
            >
              <option value="">Please make a selection</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Not Sure">Not sure</option>
            </Field>
            <ErrorMessage
              name="publishes"
              component="div"
              className="field-error"
            />

            <button type="submit" className={sendButtonClassNames}>
              {sendButtonText}
            </button>
          </Form>
        )}
      />
    );
  }
}

export default EarlyAccessForm;
