import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import styles from './contact-block.module.css';
import buttonStyles from '../button.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(buttonStyles);

const ContactFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Must be longer than 2 characters')
    .required('Please enter your name'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Required'),
  message: Yup.string().required('Please enter a message'),
});

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    ContactForm.propTypes = {
      submitStatus: PropTypes.func.isRequired,
    };
    this.state = {
      // To make code simpler below, we'll set submitStatus to be the same as what we want the text of the submit button to be: "Send" if not submitted, "Sending..." if in the process of submitting, and "Sent" if submitted.
      submitStatus: 'Send',
    };
    this.sendMessage = this.sendMessage.bind(this);
  }

  async sendMessage(formValues) {
    this.setState({ submitStatus: 'Sending...' });
    const from = formValues.email;
    const to = 'contact@1brand.co';
    const subject = `New message from ${formValues.name}`;
    const text = formValues.message;
    const emailFields = { to, from, subject, text };
    const apiEndpoint =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:4000'
        : 'https://3lxwcogoc5.execute-api.us-east-2.amazonaws.com/v1/prelaunchContactFormProcessor';
    // Submit to AWS API Gateyway for processing by a Lambda function. **NOTE**: API Gateway uses request body validation. If emailFields changes, the validation model will need to be updated.
    await axios
      .post(apiEndpoint, emailFields)
      .then(this.setState({ submitStatus: 'Sent' }))
      .catch(err => {
        console.error(err.config);
        this.props.submitStatus('failed');
      });
  }

  render() {
    /* If form has not been sent (based on state), it's a "Send" button, and it has the normal button background. If form **has** been sent, it's a "Sent" button and has a darker background. */
    const sendButtonText = this.state.submitStatus;
    const sendButtonClassName =
      this.state.submitStatus === 'Send'
        ? buttonStyles.contactSend
        : buttonStyles.contactSent;
    // Use classnames (cx) to join the above className with the universal ".button" className to be passed in all at once to the button.
    const sendButtonClassNames = cx('button', sendButtonClassName);
    const initialFormValues = {
      name: '',
      email: '',
      message: '',
    };
    return (
      <Formik
        initialValues={initialFormValues}
        validationSchema={ContactFormSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          actions.resetForm(initialFormValues);
          this.sendMessage(values);
        }}
        render={({ errors, touched }) => (
          <Form className={styles.contactForm}>
            <label htmlFor="name">Name</label>
            <Field name="name" placeholder="John Doe" type="text" />

            <ErrorMessage name="name" component="div" className="field-error" />

            <label htmlFor="email">Email</label>
            <Field name="email" placeholder="Your@email.com" type="email" />
            <ErrorMessage
              name="email"
              component="div"
              className="field-error"
            />

            <label htmlFor="message">Message</label>
            <Field
              name="message"
              placeholder="Write your message"
              component="textarea"
              rows="7"
              className={styles.contactMessage}
            />
            <ErrorMessage
              name="message"
              component="div"
              className="field-error"
            />

            <button
              type="submit"
              className={sendButtonClassNames}
              disabled={!(this.state.submitStatus === 'Send')}
            >
              {sendButtonText}
            </button>
          </Form>
        )}
      />
    );
  }
}

export default ContactForm;
