import React from 'react';
import logo from './images/logo.svg';
import CtaButton from './cta-button';
import styles from './navbar.module.css';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    // set initial state of the navbar toggler to not-toggled, so if the user is on a small screen width the menu will start off collapsed
    this.state = {
      isOpen: false,
    };
  }

  // Whenever the toggler button is clicked, the collapsed/expanded state will become the opposite of whatever it already was.
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <Navbar
        light
        expand="sm"
        className={`fixed-top ${styles.navbarContainer}`}
      >
        <NavbarBrand href="/">
          <img src={logo} className={styles.navLogo} />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} className={styles.navbarToggler} />
        <Collapse
          isOpen={this.state.isOpen}
          navbar
          className="menuItemsContainer"
        >
          <Nav className={`ml-auto ${styles.navToggleCentered}`} navbar>
            <NavItem>
              <NavLink href="/#contact">Contact</NavLink>
            </NavItem>
            <NavItem>
              <CtaButton
                linkTo="/early-access"
                className="ctaButtonNavbar"
                buttonText="Early Access"
              />
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
