import React from 'react';
import Waypoint from 'react-waypoint';
import urlAnimationStyles from './url-animation.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(urlAnimationStyles);

class UrlAnimation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      classes: urlAnimationStyles.typewriterText,
    };
    this._handleEnter = this._handleEnter.bind(this);
  }

  _handleEnter() {
    const classes = cx({
      typewriterText: true,
      typewriter: true,
    });
    this.setState({ classes: classes });
  }

  render() {
    return (
      <Waypoint onEnter={this._handleEnter}>
        <div className={urlAnimationStyles.animatedContent1}>
          <div className={urlAnimationStyles.urlField}>
            <span className={urlAnimationStyles.purpleText}>https://</span>
            <div className={this.state.classes}>1brand.co</div>
          </div>
          <div className={urlAnimationStyles.doneButton}>Done</div>
        </div>
      </Waypoint>
    );
  }
}

export default UrlAnimation;
