import React from 'react';
import Waypoint from 'react-waypoint';
import styles from './slider-animation.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

class SliderAnimation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gradient1Classes: cx('gradient', 'gradient1'),
      handle1Classes: cx('handle', 'handle1'),
      gradient2Classes: cx('gradient', 'gradient2'),
      handle2Classes: cx('handle', 'handle2'),
    };
    this._handleEnter = this._handleEnter.bind(this);
  }

  updatePercentage(start, end, element, duration) {
    if (element.innerHTML === start + '%') {
      const numberOfSteps = end - start;
      let i = start;
      const inv = setInterval(function() {
        if (i < end) element.innerHTML = ++i + '%';
        else clearInterval(inv);
      }, duration / numberOfSteps);
    }
  }

  _handleEnter() {
    const classes = { ...this.state };
    classes.gradient1Classes = cx('gradient', 'gradient1', 'adjusted');
    classes.handle1Classes = cx('handle', 'handle1', 'adjusted');
    classes.gradient2Classes = cx('gradient', 'gradient2', 'adjusted');
    classes.handle2Classes = cx('handle', 'handle2', 'adjusted');
    this.setState({ ...classes });
    const percentage1 = document.getElementsByClassName(styles.percentage)[0];
    const percentage2 = document.getElementsByClassName(styles.percentage)[1];
    this.updatePercentage(30, 80, percentage1, 3000);
    setTimeout(() => {
      this.updatePercentage(60, 90, percentage2, 2500);
    }, 4000);
  }

  render() {
    return (
      <Waypoint onEnter={this._handleEnter}>
        <div className={styles.animatedContent3}>
          <div className={styles.sliderSet}>
            <div className={this.state.gradient1Classes} />
            <div className={this.state.handle1Classes} />
            <div className={cx('percentage', 'percentage1')}>30%</div>
          </div>
          <div className={styles.sliderSet}>
            <div className={this.state.gradient2Classes} />
            <div className={this.state.handle2Classes} />
            <div className={cx('percentage', 'percentage2')}>60%</div>
          </div>
        </div>
      </Waypoint>
    );
  }
}

export default SliderAnimation;
