import React from 'react';
import Layout from '../components/layout';
import TopCta from '../components/top-cta';
import EarlyAccessFormContainer from '../components/early-access-form';

export default () => (
  <Layout>
    <TopCta>
      <EarlyAccessFormContainer />
    </TopCta>
  </Layout>
);
