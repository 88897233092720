import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, Link, graphql } from 'gatsby';
import Navigation from './navbar';
import Footer from './footer/';
import './bootstrap.min.css';
import './layout.css';

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Navigation />
        {children}
        <Footer />
      </React.Fragment>
    )}
  />
);
