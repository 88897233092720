import React from 'react';
import footerStyles from './footer.module.css';
import { Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
import SocialButton from './socialButton';

const copyrightDate = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className={footerStyles.footer}>
      <Row className="justify-content-between align-items-center">
        <Col xl="3" lg="12" className={footerStyles.socialIcons}>
          {/* <SocialButton name="Instagram" /> */}
          <SocialButton name="Twitter" />
          {/* <SocialButton name="Pinterest" />
          <SocialButton name="Dribbble" />
          <SocialButton name="Behance" /> */}
        </Col>
        <Col xl="6" lg="12" className={footerStyles.legal}>
          <span className="text-nowrap">
            © Copyright {copyrightDate} Permanance Labs.&nbsp;
          </span>
          <span className="text-nowrap">
            <Link to="/privacy-policy">Privacy Policy </Link>| Terms &
            Conditions
          </span>
        </Col>
        <Col xl="3" lg="12" className={footerStyles.credits}>
          <span className="text-nowrap">
            Identity by
            <a href="https://derricdraws.com"> Derric Wise</a> & Design by
            <a href="https://fello.co"> fello</a>.
          </span>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
