import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import styles from './socialButton.module.css';

const SocialButton = props => {
  SocialButton.propTypes = {
    name: PropTypes.string,
  };
  // className for the img is all lowercase, so we lowercase the name just in case it was passed in non-lowercase
  const name = props.name.toLowerCase();
  return (
    <a href={`https://${name}.com/1brandwins`}>
      <img
        src={withPrefix(`/images/${name}.svg`)}
        className={`${styles.socialIcon} ${name}`}
        alt={`${props.name} Icon`}
      />
    </a>
  );
};

export default SocialButton;
